import * as React from "react"

import Subheader from "../../components/content_blocks/special/subheader"
import ImageBlock from "../../components/elements/imageBlock"
//elements
import Layout from "../../components/layout"
import "./style.scss"

const Post = ({ pageContext }) => {
  const {
    post: { title, content, featuredImage },
  } = pageContext

  // create subheader data
  const subheaderdata = {
    heading: title,
  }

  //match header data structure from templates/page/index.js
  const headerData = {
    bannerImage: {
      sourceUrl: featuredImage.node.sourceUrl,
    },
  }

  return (
    <Layout className="post" headerData={headerData}>
      <Subheader {...subheaderdata} />
      <main className="post-container">
        <div className="post-content">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <div className="post-side">
          <ImageBlock imageSrc={featuredImage.node.sourceUrl} />
        </div>
      </main>
    </Layout>
  )
}

export default Post
