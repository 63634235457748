import React from "react"

import HeaderBlock from "../../../elements/headingBlock"
import Breadcrumbs from "./breadcrumbs"
import "./subheader.scss"

const Subheader = data => {
  const heading = data?.heading
  const breadcrumbs = data?.heading
  const leadingText = data?.leadingText
  const bodyText = data?.bodyText
  return (
    <section className="subheader">
      <div className="subheader-container">
        <div className="subheader-header">
          <HeaderBlock text={heading} />
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>

        {/* only display body text if leading and body text are provided */}
        {(leadingText || bodyText) && (
          <div className="subheader-body">
            <div
              className="leadingText"
              dangerouslySetInnerHTML={{ __html: leadingText }}
            />
            <div
              className="bodyText"
              dangerouslySetInnerHTML={{ __html: bodyText }}
            />
          </div>
        )}
      </div>
    </section>
  )
}
export default Subheader
