import React from "react"
import PageLink from "../../../elements/pageLink"

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className="breadcrumbs">
      <span>
        <PageLink href="/" target="_self">
          Home
        </PageLink>{" "}
        /{" "}
      </span>
      <strong>{breadcrumbs}</strong>
    </div>
  )
}

export default Breadcrumbs
